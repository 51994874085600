import Page from 'components/Page';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {Button, Card, CardBody, CardHeader, Col, Table, Row, FormGroup, Label, Input, Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import MerchantItem from './TicketItem';
// MODAL CREATE DYNAMIC MODALS
import Dialogue from '../../components/common/Modal/a.index';
import DialogueTitle from '../../components/common/Modal/Title';
import DialogueContent from '../../components/common/Modal/Content';
import DialogueAction from '../../components/common/Modal/Action';
import { MerchantDetails } from './TicketDetails';
import LoadSpinner from '../../components/common/spinner';
import spin from '../../utils/spin.gif';
import { MOVIE_DASHBOARD_API_LOCAL, Axios } from '../../redux/axios.request.link';

import { getMerchants, getMerchant } from '../../redux/actions/merchant';

let getData = (search, from, to) => {

  if (search == undefined){
    search = ""
  }
  if (from == undefined){
    from = ""
  }
  if (to == undefined){
    to = ""
  }
  let u = window.localStorage.getItem("user")
  let user = JSON.parse(u)
  console.log(user)
  let add = `?search=${search}&from=${from}&to=${to}&_id=${user._id}`
  window.location = MOVIE_DASHBOARD_API_LOCAL + `/api/distributor/tickets/xls${add}`
};

class ListMerchants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      searching: true,
      query: "",
      from: "",
      to: ""
    };

    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { getMerchants } = this.props;
    getMerchants();
  }

  toggle(_id) {
    if (_id) {
      this.props.getMerchant(_id);
    }
    this.setState(prevState => ({modal: !prevState.modal}));
  }

  close() {
    this.setState(prevState => ({modal: !prevState.modal}));
  }



  onChange = async e => {
    let search = e.target.value;
    let {merch: { loading }, getMerchants} = this.props;
    this.setState({query: search})
    if (!loading){
      getMerchants(search, false, 1, this.state.from, this.state.to)
    }
  };

  render() {
    const {merch: { loading, merchants, merchant, next, page, total }, getMerchants} = this.props;
    let listMerchantItems;

    const externalCloseBtn = (
      <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.close}>
        &times;
      </button>
    );

    let dialogueTitle;
    let dialogueContent;
    let merchantDialog;
    let dialogueAction;

    if (merchant && !loading) {
      // populates data table loaidng a merchant??
      dialogueTitle = (
        <DialogueTitle simpleContext={ merchant === null || merchant === undefined ? '' : merchant.name}/>
      );

      dialogueContent = (
        <DialogueContent
          contextComponent={ merchant === null ? ( <LoadSpinner src={spin} />) : ( <MerchantDetails {...merchant} />)}
        />
      );

      dialogueAction = (
        <DialogueAction
          contextComponent={
            <Button color="secondary" onClick={this.close}>
              Cancel
            </Button>
          }
        />
      );

      merchantDialog = (
        <Dialogue DialogTitle={dialogueTitle} DialogContent={dialogueContent} DialogActions={dialogueAction} isOpen={this.state.modal} toggle={this.toggle} externalCloseBtn={externalCloseBtn}/>
      );
    } else {
      merchantDialog = <React.Fragment />;
    }

    loading === true && merchants == null
      ? (listMerchantItems = <React.Fragment />)
      : loading === false && merchants == null
      ? (listMerchantItems = <React.Fragment />)
      : merchants.length > 0
      ? (listMerchantItems = merchants.map((merch, i) => (
          <MerchantItem key={merch._id} toggle={this.toggle} history={this.props.history} index={i} ticketDetails={merch} />
        )))
      : (listMerchantItems = <h4>No Ticket  Found!</h4>);

    return (
      <React.Fragment>
        {merchantDialog}
        {/* <DemoModal modal={this.state.modal} toggle={this.toggle} /> */}
        <Page title="List Tickets" breadcrumbs={[{ name: 'List Tickets', active: true }]}>
          <Row>
            <Col xl={12} lg={12} md={12}>
              <Card>
                <CardHeader>Search for tickets</CardHeader>
                <CardBody>
                <Row>
                  <Col xl={12} lg={12} md={12}>
                  <FormGroup>
                      <Input type="text" name="search" placeholder="Name/email/phone" onChange={this.onChange}/>
                  </FormGroup>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <FormGroup>
                  <Label for="from">FROM DATE</Label>
                      <Input type="date" name="from" placeholder="select date" onChange={(e) => {
                        this.setState({from: e.target.value});
                        console.log(e.target.value);
                        }}/>
                  </FormGroup>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <FormGroup>
                  <Label for="from">TO DATE</Label>
                      <Input type="date" name="to" placeholder="select date" onChange={(e) => {
                        this.setState({to: e.target.value});
                        console.log(e.target.value);
                        getMerchants(this.state.query, false, 1, this.state.from, e.target.value)
                        }}/>
                  </FormGroup>
                  </Col>
                </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row style={{marginTop: "20px"}}>
            <Col xl={4} lg={4} md={6}>
              <h3> TOTAL: {total}, CURRENT PAGE: {page}</h3>
            </Col>
            {page > 1 || next > 0 ? (
            <Col xl={4} lg={4} md={6}>
              <Pagination  size="lg" aria-label="Page">

                  {page > 1 ? (<PaginationItem onClick={() => {getMerchants(this.state.query, false, page - 1, this.state.from, this.state.to)}} >
                    <PaginationLink previous href="" />
                  </PaginationItem>) : ""}
                  
                  {(page - 4) > 0 ? (
                  <PaginationItem onClick={() => {getMerchants(this.state.query, false, page - 4, this.state.from, this.state.to)}}>
                    <PaginationLink href="#">
                      {page - 4}
                    </PaginationLink>
                  </PaginationItem>) : ""}

                  {(page - 3) > 0 ? (
                  <PaginationItem onClick={() => {getMerchants(this.state.query, false, page - 3, this.state.from, this.state.to)}}>
                    <PaginationLink href="#">
                      {page - 3}
                    </PaginationLink>
                  </PaginationItem>) : ""}

                  {(page - 2) > 0 ? (
                  <PaginationItem onClick={() => {getMerchants(this.state.query, false, page - 2, this.state.from, this.state.to)}}>
                    <PaginationLink href="#">
                      {page - 2}
                    </PaginationLink>
                  </PaginationItem>) : ""}

                  {(page - 1) > 0 ? (
                  <PaginationItem onClick={() => {getMerchants(this.state.query, false, page - 1, this.state.from, this.state.to)}}>
                    <PaginationLink href="#">
                      {page - 1}
                    </PaginationLink>
                  </PaginationItem>) : ""}
        
                  {next ? (<PaginationItem onClick={() => {getMerchants(this.state.query, false, next); console.log("ooooooo") }}>
                    <PaginationLink next href="#" />
                  </PaginationItem>) : ""}

              </Pagination>
            </Col>) : ""}
            <Col xl={4} lg={4} md={6}>
              <Button onClick={() => {getData(this.state.search, this.state.from, this.state.to); console.log("got here")}} style={{float: "right"}} outline >
                DOWNLOAD DATA
              </Button>
            </Col>
          </Row>

          <Row>
            <Col xl={12} lg={12} md={12}>
                <h2>Ticket List</h2>

                    <div body style={{overflowX: "auto", whiteSpace: "nowrap", minWidth: "700px"}}>
                      <Table striped={true} id="listmerchantstable">
                        <thead>
                          <tr>
                            <th>Details</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Validity</th>
                            <th>Movie Title</th>
                            <th>Showtime Date</th>
                            <th>Purchased Date</th>

                          </tr>
                        </thead>
                        <tbody>
                        {listMerchantItems}
                        </tbody>
                      </Table>
                    </div>

            </Col>
          </Row>
        </Page>
      </React.Fragment>
    );
  }
}

const map_state_to_props = state => ({
  merch: state.merchant,
});

export default connect(map_state_to_props, { getMerchants, getMerchant } )(withRouter(ListMerchants));
